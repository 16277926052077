import { cloneDeep, isEqual } from 'lodash-es';
import {
    EMPTY,
    Observable,
    Subscription,
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    from,
    map,
    of,
    pairwise,
    startWith,
    switchMap,
    take
} from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericOption } from '@mhp-immersive-exp/contracts/src/configuration/configuration-payload.interface';
import {
    ApplicationNavigationArea,
    EngineSessionData,
    SessionUrlService
} from '@mhp/aml-ui-shared-services';
import {
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import { AnimationState } from '@mhp/communication-models';
import {
    AlternateDataSource,
    ApplicationStateService,
    ProductDataService,
    SerializerService,
    selectAnimationStates,
    selectCameraState,
    selectEnvironmentState,
    selectProductState
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { ROUTE_CONFIGURATION } from '../../app-route-names';
import { NavigationStateService } from '../../navigation/navigation-state.service';
import { RegionService } from '../../settings/region-selector/region.service';
import { LocalApplicationState } from '../../state';
import {
    CODE_LIGHTS_OFF,
    CODE_LIGHTS_ON
} from '../car-features/car-feature-control.service';
import { ConfigurationSessionInfo } from '../common/configuration-interfaces';
import { selectActiveModelId } from '../state';
import { SessionUrlStateUpdateState } from './session-url-state-update-state.interface';

export declare type CompleteLocationSessionData = Omit<
    EngineSessionData,
    'country'
>;

export declare type PartialLocationSessionData =
    Partial<CompleteLocationSessionData>;

@Injectable({
    providedIn: 'root'
})
export class ConfigurationSessionInfoService {
    private syncSessionSubscription?: Subscription;

    private readonly watermarkSource = new AlternateDataSource<
        string | undefined
    >();

    constructor(
        private readonly router: Router,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly serializerService: SerializerService,
        private readonly regionService: RegionService,
        private readonly productDataService: ProductDataService,
        private readonly navigationStateService: NavigationStateService,
        private readonly sessionUrlService: SessionUrlService
    ) {}

    /**
     * Returns the currently active modelId based on the active session info.
     * See #getActiveConfigurationSessionInfo
     */
    getActiveModelId$(): Observable<string | undefined> {
        return this.getActiveConfigurationSessionInfo$().pipe(
            map((sessionInfo) => sessionInfo?.modelId),
            lazyShareReplay(),
            distinctUntilChanged()
        );
    }

    /**
     * Returns the currently active productId based on the active session info.
     * See #getActiveConfigurationSessionInfo
     */
    getActiveProductId$(): Observable<string | undefined> {
        return this.getActiveConfigurationSessionInfo$().pipe(
            map((sessionInfo) => sessionInfo?.productId),
            lazyShareReplay(),
            distinctUntilChanged()
        );
    }

    /**
     * Get the current ConfigurationSessionInfo state, taking the currently active navigation area into account.
     * In case we're outside of the ApplicationNavigationArea.CONFIGURATION area, session-info is undefined.
     */
    @MemoizeObservable()
    getActiveConfigurationSessionInfo$(): Observable<
        ConfigurationSessionInfo | undefined
    > {
        return combineLatest([
            this.regionService.getActiveRegion$(),
            this.applicationStateService
                .getLocalState()
                .pipe(selectActiveModelId),
            this.getActiveEngineSessionData$()
        ]).pipe(
            map(([activeCountry, activeModelId, activeEngineData]) => {
                if (!activeCountry || !activeModelId || !activeEngineData) {
                    return undefined;
                }
                return {
                    modelId: activeModelId,
                    productId: activeEngineData.config.id,
                    country: activeCountry,
                    engineData: activeEngineData
                };
            }),
            map((sessionInfo) =>
                sessionInfo ? Object.freeze(sessionInfo) : undefined
            ),
            distinctUntilChanged(isEqual),
            lazyShareReplay()
        );
    }

    startSyncSessionToUrl() {
        if (this.syncSessionSubscription) {
            return;
        }

        const isSessionDataUpdateInProgress$ = this.applicationStateService
            .getLocalState()
            .pipe(
                map(
                    (state) => state.configuration?.updateSessionDataInProgress
                ),
                distinctUntilChanged()
            );

        this.syncSessionSubscription = combineLatest({
            configurationSessionInfo:
                this.getActiveConfigurationSessionInfo$().pipe(debounceTime(0)),
            sessionDataUpdateInProgress: isSessionDataUpdateInProgress$
        })
            .pipe(
                startWith(undefined),
                pairwise(),
                switchMap(([previous, current]) => {
                    const configurationSessionInfoChanged = isEqual(
                        previous?.configurationSessionInfo,
                        current?.configurationSessionInfo
                    );

                    // do not perform navigation updates in case there is a session-update in progress OR if there has been no change in the session data
                    if (
                        current?.sessionDataUpdateInProgress ||
                        configurationSessionInfoChanged
                    ) {
                        return EMPTY;
                    }

                    let navigationResult$;
                    if (!current?.configurationSessionInfo) {
                        navigationResult$ = this.clearSessionInfo();
                    } else {
                        navigationResult$ = this.writeToUrl$(
                            current.configurationSessionInfo.engineData
                        );
                    }
                    return navigationResult$.pipe(
                        catchError((error) => {
                            console.error(
                                `Failed syncing session to URL`,
                                error
                            );
                            return EMPTY;
                        })
                    );
                })
            )
            .subscribe((navigationResult) => {
                if (navigationResult === false) {
                    console.error(`Failed syncing session to URL`);
                }
            });
    }

    stopSyncSessionToUrl() {
        this.syncSessionSubscription?.unsubscribe();
        this.syncSessionSubscription = undefined;
    }

    @MemoizeObservable()
    getNormalizedCurrentConfigurationUrl$(): Observable<string | undefined> {
        return this.getNormalizedConfigurationUrlInternal$(
            window.location.origin
        );
    }

    /**
     * Emits the currently active url (public or dealer, depending on where the user is accessing the configurator) containing all information to
     * start a new session based on the active config.
     */
    @MemoizeObservable()
    getCurrentConfigurationUrl$(): Observable<string | undefined> {
        return this.getCurrentConfigurationUrlInternal$(window.location.origin);
    }

    /**
     * Emits the currently active PUBLIC url containing all information to
     * start a new session based on the active config.
     */
    @MemoizeObservable()
    getCurrentConfigurationPublicUrl$(): Observable<string | undefined> {
        return this.getCurrentConfigurationUrlInternal$(
            environment.appConfig.baseUrls.publicBaseUrl
        );
    }

    /**
     * Emits the currently active DEALER url containing all information to
     * start a new session based on the active config.
     */
    @MemoizeObservable()
    getCurrentConfigurationDealerUrl$(): Observable<string | undefined> {
        return this.getCurrentConfigurationUrlInternal$(
            environment.appConfig.baseUrls.dealerBaseUrl
        );
    }

    /**
     * Emits the currently active PUBLIC url containing the configuration-information only
     * to start a new session with.
     */
    @MemoizeObservable()
    getNormalizedConfigurationPublicUrl$(): Observable<string | undefined> {
        return this.getNormalizedConfigurationUrlInternal$(
            environment.appConfig.baseUrls.publicBaseUrl
        );
    }

    /**
     * Emits the currently active DEALER url containing the configuration-information only
     * to start a new session with.
     */
    @MemoizeObservable()
    getNormalizedConfigurationDealerUrl$(): Observable<string | undefined> {
        return this.getNormalizedConfigurationUrlInternal$(
            environment.appConfig.baseUrls.dealerBaseUrl
        );
    }

    writeToUrl$(sessionData: EngineSessionData): Observable<boolean> {
        return from(
            this.router.navigate(
                this.sessionUrlService.buildUrlCommands(sessionData),
                {
                    replaceUrl: false,
                    queryParamsHandling: 'merge',
                    state: <SessionUrlStateUpdateState>{
                        internalStateUpdate: true
                    }
                }
            )
        );
    }

    getSerializedLocationEngineSessionData(
        sessionData: CompleteLocationSessionData
    ) {
        return this.sessionUrlService.getSerializedLocationEngineSessionData(
            sessionData
        );
    }

    getDeserializedPartialLocationEngineSessionData(
        serializedSessionData: string
    ) {
        return this.sessionUrlService.getDeserializedPartialLocationEngineSessionData(
            serializedSessionData
        );
    }

    /**
     * Register an observable stream providing watermark information that is
     * used when creating image sources.
     * @param watermarkSource$
     * @return Cleanup-callback to remove registered provider.
     */
    registerWatermarkSource(watermarkSource$: Observable<string | undefined>) {
        return this.watermarkSource.registerAlternateDataSource(
            watermarkSource$
        );
    }

    /**
     * Get the current EngineSessionData state, taking the currently active navigation area into account.
     * In case we're outside of the ApplicationNavigationArea.CONFIGURATION area, session-info is undefined.
     */
    @MemoizeObservable()
    private getActiveEngineSessionData$(): Observable<
        EngineSessionData | undefined
    > {
        return combineLatest({
            activeCountry: this.regionService.getActiveRegion$(),
            // active configuration
            productState: this.applicationStateService
                .getState()
                .pipe(selectProductState),
            // active environment
            environmentState: this.applicationStateService
                .getState()
                .pipe(selectEnvironmentState),
            // active camera - emit only, when active camera can be matched to an available camera
            activeCamera: combineLatest([
                this.applicationStateService.getState().pipe(selectCameraState),
                this.productDataService.getAvailableCameras$()
            ]).pipe(
                filter(([activeCamera, availableCameras]) => {
                    if (!activeCamera || !availableCameras) {
                        return true;
                    }
                    return !!availableCameras.cameras.find(
                        (camera) => activeCamera === camera.id
                    );
                }),
                map(([activeCamera]): string | undefined => activeCamera)
            ),
            // active animations
            activeAnimations: this.applicationStateService
                .getState()
                .pipe(selectAnimationStates),
            activeApplicationNavigationArea:
                this.navigationStateService.getActiveApplicationNavigationArea$(),
            activeWatermark: this.watermarkSource.getAlternateData$()
        }).pipe(
            debounceTime(0),
            distinctUntilChangedEquality(),
            map(
                ({
                    activeCountry,
                    productState,
                    environmentState,
                    activeCamera,
                    activeAnimations,
                    activeApplicationNavigationArea,
                    activeWatermark
                }): EngineSessionData | undefined => {
                    if (
                        !activeCountry ||
                        !productState ||
                        !environmentState ||
                        !activeCamera
                    ) {
                        return undefined;
                    }

                    // check if we are in the configuration-area. If not, there is no active session info
                    if (
                        activeApplicationNavigationArea !==
                        ApplicationNavigationArea.CONFIGURATION
                    ) {
                        return undefined;
                    }

                    const watermarkState = activeWatermark
                        ? {
                              text: activeWatermark
                          }
                        : undefined;

                    const engineSessionData: EngineSessionData = {
                        country: activeCountry,
                        config: {
                            id: productState.id,
                            options: {
                                config: productState.configOptions
                            }
                        },
                        environment: {
                            id: environmentState.id,
                            options: {
                                night: environmentState.state === 'NIGHT'
                            }
                        },
                        camera: {
                            id: activeCamera,
                            options: {}
                        },
                        animations:
                            activeAnimations
                                ?.filter(
                                    (animationState: AnimationState) =>
                                        animationState.state === 'END'
                                )
                                .map(
                                    (
                                        animationState: AnimationState
                                    ): GenericOption => ({
                                        id: animationState.id,
                                        options: {}
                                    })
                                ) ?? []
                    };

                    if (watermarkState) {
                        engineSessionData.meta = {
                            watermark: watermarkState
                        };
                    }

                    return engineSessionData;
                }
            ),
            map((sessionInfo) =>
                sessionInfo ? Object.freeze(sessionInfo) : undefined
            ),
            lazyShareReplay()
        );
    }

    private clearSessionInfo() {
        return combineLatest([
            this.regionService.getActiveRegion$(),
            this.applicationStateService.getState().pipe(selectProductState)
        ]).pipe(
            first(),
            switchMap(([activeRegion, activeProductState]) => {
                if (!activeProductState) {
                    return this.router.navigate(
                        [activeRegion, ROUTE_CONFIGURATION],
                        {
                            replaceUrl: true,
                            queryParamsHandling: 'merge'
                        }
                    );
                }
                return this.router.navigate(
                    [activeRegion, ROUTE_CONFIGURATION, activeProductState.id],
                    {
                        replaceUrl: true,
                        queryParamsHandling: 'merge'
                    }
                );
            })
        );
    }

    /**
     * Get the current EngineSessionData state, but with some defaults (environment night state, animations...) set.
     */
    private getNormalizedConfigurationUrlInternal$(
        origin: string
    ): Observable<string | undefined> {
        return this.getActiveConfigurationSessionInfo$().pipe(
            switchMap((activeConfigurationSessionInfo) => {
                if (!activeConfigurationSessionInfo) {
                    return of(undefined);
                }
                return this.normalizeSessionInfo$(
                    activeConfigurationSessionInfo
                ).pipe(
                    map((normalizedSessionInfo) =>
                        this.sessionUrlService.buildUrlCommands(
                            normalizedSessionInfo.engineData
                        )
                    )
                );
            }),
            map((urlCommands) => {
                if (!urlCommands) {
                    return undefined;
                }
                return `${origin}${this.router.createUrlTree(urlCommands)}`;
            }),
            lazyShareReplay()
        );
    }

    /**
     * Returns session info with some defaults reset.
     * Emits once and completes.
     */
    private normalizeSessionInfo$(
        inputSessionInfo: ConfigurationSessionInfo
    ): Observable<ConfigurationSessionInfo> {
        return combineLatest([
            // default ext camera
            this.productDataService
                .getAvailableCameras$()
                .pipe(map((cameras) => cameras?.defaultExt)),
            // default environment
            this.productDataService
                .getDefaultEnvironment$(inputSessionInfo.productId)
                .pipe(map((env) => env?.id))
        ]).pipe(
            take(1),
            map(([defaultExtCamId, defaultEnvironmentId]) => {
                // deep copy because state object could be read-only
                const sessionData = cloneDeep(inputSessionInfo);
                const { engineData } = sessionData;

                // force day + lights off
                engineData.environment.options.night = false;
                engineData.config.options.config =
                    engineData.config.options.config.filter(
                        (configModelEntry) =>
                            configModelEntry !== CODE_LIGHTS_OFF &&
                            configModelEntry !== CODE_LIGHTS_ON
                    );
                engineData.config.options.config.push(CODE_LIGHTS_OFF);
                // force no animations
                engineData.animations = [];
                // apply default environment
                if (defaultEnvironmentId) {
                    engineData.environment.id = defaultEnvironmentId;
                }
                // apply default ext cam
                if (defaultExtCamId) {
                    engineData.camera.id = defaultExtCamId;
                }

                return sessionData;
            })
        );
    }

    private getCurrentConfigurationUrlInternal$(
        origin: string
    ): Observable<string | undefined> {
        return this.getActiveConfigurationSessionInfo$().pipe(
            map((activeConfigurationSessionInfo) => {
                if (!activeConfigurationSessionInfo) {
                    return undefined;
                }
                return this.sessionUrlService.buildUrlCommands(
                    activeConfigurationSessionInfo.engineData
                );
            }),
            map((urlCommands) => {
                if (!urlCommands) {
                    return undefined;
                }
                return `${origin}${this.router.createUrlTree(urlCommands)}`;
            }),
            lazyShareReplay()
        );
    }
}
